import React, { useState } from "react";
import { Col } from "react-bootstrap";
import phstarfill from "../../images/icons/ph_star-fill.svg";
import clock from "../../images/icons/clock.svg";
import calendar from "../../images/icons/calendar.svg";
import bell from "../../images/icons/bell.svg";
import playicon from "../../images/icons/play_circle_filled.svg";
import { Script } from "gatsby"


import Popup from "reactjs-popup";

function Amaladdertwittercard(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [loaded, setLoaded] = useState(false)
  return (
    <Col lg={4} className=" d-flex justify-content-center">
      <div className="laddercard d-flex flex-column align-items-center" onClick={() => setOpen(o => !o)}>
        <div className="w-100 mb-3 position-relative">
          <img className="img-fluid rounded" src={props.thumb} alt="" />
          <img className="position-absolute playiconpos img-fluid rounded" src={playicon} alt="" />
        </div>

        <div className="parentladderhead position-relative">
          <h5 className="ladderheader">
            <span className="ladderheadergold">{props.goldtitle}</span> : {props.title}
          </h5>
          <div className="voteladderbtn ">
            <div className="voteworldclass2 d-flex justify-content-center align-items-center position-relative">
              <img className="pr-1 starsize" src={phstarfill} alt="" />
              <span className="votetext2">{props.votecount} Votes</span>
            </div>
          </div>
          <div className="bottomladderdetail d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={clock} alt="" />
              <span className="blogblogtexty">{props.duration} Mins</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={calendar} alt="" />
              <span className="blogblogtexty">{props.date}</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={bell} alt="" />
              <span className="blogblogtexty">{props.time} UTC</span>
            </div>
          </div>
        </div>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modalopen">
          <a className="modalclose" onClick={closeModal}>
            &times;
          </a>
          <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Today Mason will be talking to Punter from blockblend about life and success. <br/><br/>Win $200 by asking the most thoughtful question on HedgeTalk. To ask question, fill this form:<a href="https://t.co/t8IEDOfzE2">https://t.co/t8IEDOfzE2</a><a href="https://t.co/jNhfTe6lAi">https://t.co/jNhfTe6lAi</a></p>&mdash; HedgePay (@HedgePay_) <a href="https://twitter.com/HedgePay_/status/1568563211821273088?ref_src=twsrc%5Etfw">September 10, 2022</a></blockquote>
          <Script src="https://platform.twitter.com/widgets.js" onLoad={() => setLoaded(true)} />
          {loaded && <Script src="https://platform.twitter.com/widgets.js" />}
        </div>
      </Popup>
    </Col>
  );
}

export default Amaladdertwittercard;
