import React, { useState } from "react";
import { Col } from "react-bootstrap";
import phstarfill from "../../images/icons/ph_star-fill.svg";
import clock from "../../images/icons/clock.svg";
import calendar from "../../images/icons/calendar.svg";
import bell from "../../images/icons/bell.svg";
import playicon from "../../images/icons/play_circle_filled.svg";


import Popup from "reactjs-popup";

function Amaladdercard(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  return (
    <Col lg={4} className=" d-flex justify-content-center">
      <button className="removebtnstyle laddercard d-flex flex-column align-items-center" onClick={() => setOpen(o => !o)}>
        <div className="w-100 mb-3 position-relative">
          <img className="img-fluid rounded" src={props.thumb} alt="" />
          <img className="position-absolute playiconpos img-fluid rounded" src={playicon} alt="" />
        </div>

        <div className="parentladderhead position-relative">
          <h5 className="ladderheader text-left">
            <span className="ladderheadergold">{props.goldtitle}</span> : {props.title}
          </h5>
          <div className="voteladderbtn ">
            <div className="voteworldclass2 d-flex justify-content-center align-items-center position-relative">
              <img className="pr-1 starsize" src={phstarfill} alt="" />
              <span className="votetext2">{props.votecount} Votes</span>
            </div>
          </div>
          <div className="bottomladderdetail d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={clock} alt="" />
              <span className="blogblogtexty">{props.duration} Mins</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={calendar} alt="" />
              <span className="blogblogtexty">{props.date}</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <img className="clockblogpost mr-2" src={bell} alt="" />
              <span className="blogblogtexty">{props.time} UTC</span>
            </div>
          </div>
        </div>
      </button>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modalopen">
          <button className="removebtnstyle modalclose" onClick={closeModal}>
            &times;
          </button>
          <iframe className="w-100 h-100 videoplayc" src={props.videodis} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Popup>
    </Col>
  );
}

export default Amaladdercard;
